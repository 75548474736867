import React, { Fragment, useRef } from 'react';
import { graphql } from 'gatsby';
import { Hero } from '@layout';
import { Box, merge } from 'theme-ui';
import CardList from '@components/CardList';
import Layout from '@solid-ui-layout/Layout';
import Seo from '@solid-ui-components/Seo';
import Divider from '@solid-ui-components/Divider';
import Header from '@solid-ui-blocks/Header/Block01';
import Footer from '@solid-ui-blocks/Footer/Block01';
import { normalizeBlockContentNodes } from '@blocks-helpers';
import flowUITheme from '@elegantstack/flow-ui-theme/src/theme';
import theme from './new-landing/_theme';
import HeroBlock from '../components/old-landing/HeroBlock';
import Services from '../components/old-landing/Services';
import AdminOrJoiner from '../components/old-landing/AdminOrJoiner';
import MobileAdminOrJoiner from '../components/old-landing/MobileAdminOrJoiner';
import GroupMembers from '../components/old-landing/GroupMembers';
import Questions from '../components/old-landing/Questions';
import Reasons from '../components/old-landing/Reasons';
import Publications from '../components/old-landing/Publications';
import HowItWorks from '../components/old-landing/HowItWorks';
import Video from '../components/old-landing/Video';
import Partners from '../components/old-landing/Partners';
import { useHasBeenVisible } from '../../hooks/useVisibility';
import FullWidthPlaceHolder from '../../content/components/shared/FullWidthPlaceHolder';
import convertServiceToPost from '../../functions/convertServiceToPost';
import ProductMetaData from '../../content/components/ProductMetaData';
import redirectToControl from '../../functions/redirectToControl';
import FreshChat from "../../content/components/FreshChat";

const styles = {
  videoWrapper: {
    height: [400, 600],
    padding: '20px 40px',
  },
  videoRoot: {
  },
  videoRow: { 
    maxWidth: 800,
    width: '100%'
  }
};


const IndexPage = (props) => {
  const { allBlockContent, productMetaDatas } = props.data;
  const controlBlockContent = redirectToControl(
    props.location.pathname,
    allBlockContent
  );
  const content = normalizeBlockContentNodes(controlBlockContent?.nodes);
  const services = convertServiceToPost({
    services: { ...props.data.serviceLandings },
    // options: {
    //   showMore: {
    //     text: content['old-serviceLandings'].description.text,
    //     link: "/servicelandings/",
    //     images: content['old-serviceLandings'].avatar
    //   },
    //   linkTitlePlaceholder: content['old-serviceLandings'].text_1.text
    // },
  });
  const halfPageReason = useRef();
  const videoRef = useRef();
  const hasScrolledReason = useHasBeenVisible(halfPageReason);
  const hasScrolledVideo = useHasBeenVisible(videoRef);
  const { GATSBY_BUILD_LANGUAGE = 'uk' } = process.env;

  return (
    <Layout theme={merge(theme, { ...flowUITheme, colors: {} })} {...props}>
      <Seo title={content['old-hero'].title.text} description={content['old-hero'].description.text}/>
      <ProductMetaData
        name='Home'
        description={content['old-hero'].text_1.text.replace('\n', ' ')}
        productMetadata={productMetaDatas.nodes[0]}
      />
      {/* Blocks */}
      <Header content={content['header']} transparent />
      <HeroBlock content={content['old-hero']} />
      {content['old-services'] && (
        <>
          <Services content={content['old-services']} />
          <Divider space='5' />
        </>
      )}
      {content['old-publications'] && (
        <>
          <Publications content={content['old-publications']} />
          <Divider space='3' />
        </>
      )}
      {content['old-how-it-works'] && (
        <>
          <HowItWorks content={content['old-how-it-works']} />
          <Divider space='3' />
        </>
      )}
      {content['old-admin-or-joiner'] && (
        <>
          <AdminOrJoiner content={content['old-admin-or-joiner']} />
          <Divider space='5' />
        </>
      )}
      {content['old-group-members'] && (
        <GroupMembers content={content['old-group-members']} />
      )}
      {content['old-questions'] && (
        <Questions content={content['old-questions']} />
      )}
      {services &&
        <Hero wide sx={{ bg: `contentBg`, pb: [3, 5], pt: [4, 5] }}>
          <Box sx={{ position: `relative`, zIndex: 2 }}>
            <CardList
              nodes={services}
              columns={[1, 2, 2, 3]}
              variant='vertical-cover'
              slider
              loading="lazy"
              autoPlay={false}
              title={content['old-serviceLandings'].kicker.text}
              aside
              withTitleLink
              customTitleLink="/servicelandings"
              customLinkPlaceholder={content['old-serviceLandings'].description.text}
              slidesToScroll={[1]}
            />
          </Box>
        </Hero>
      }
      {content['old-partners'] && (
        <Partners content={content['old-partners']} />
      )}
      {content['old-mobile-admin-or-joiner'] && (
        <MobileAdminOrJoiner content={content['old-mobile-admin-or-joiner']} />
      )}
      {GATSBY_BUILD_LANGUAGE === 'it' &&
        (hasScrolledVideo ? (
          <Box sx={styles.videoWrapper}>
            <Video styles={{ root: styles.videoRoot, row: styles.videoRow }} />
          </Box>
        ) : (
          <FullWidthPlaceHolder
            reference={videoRef}
            customStyle={{ height: '600px' }}
          />
        ))}
      {content['old-reasons'] &&
        (hasScrolledReason ? (
          <Fragment>
            <Reasons content={content['old-reasons']} />
            <Divider space='5' />
          </Fragment>
        ) : (
          <FullWidthPlaceHolder
            reference={halfPageReason}
            customStyle={{
              height: '600px',
              display: 'flex',
              justifyContent: 'center',
            }}
          />
        ))}
      {GATSBY_BUILD_LANGUAGE === 'it' && (<FreshChat/>)}
      <Footer content={content['footer']} showTrustPilot/>
    </Layout>
  );
};

export const query = graphql`
  query SiteOldIndexBlockContent($blocksPaths: [String]!) {
    allBlockContent: allBlockContent(
      filter: {
        page: { in: $blocksPaths }
        identifier: {
          in: [
            "header"
            "old-hero"
            "old-services"
            "old-publications"
            "old-how-it-works"
            "old-admin-or-joiner"
            "old-group-members"
            "old-questions"
            "old-serviceLandings"
            "old-partners"
            "old-mobile-admin-or-joiner"
            "old-reasons"
            "footer"
          ]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
    serviceLandings: allServiceLanding(filter: {featured: {eq: true}}, limit: 5) {
      nodes {
        id
        service {
          name
          slug
        }
        heroImage {
          __typename
          ... on ContentfulAsset {
            ContentfulAsset: gatsbyImageData(
              width: 500
              height: 375
              layout: FIXED
              cropFocus: TOP
              resizingBehavior: THUMB
              quality: 80
            )
            localFile_regular_mobile: localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 500
                  height: 375
                  layout: FIXED
                  transformOptions: { cropFocus: NORTH }
                  quality: 80
                )
              }
            }
          }
        }
      }
    }
    productMetaDatas: allProductMetaData {
      nodes {
        ...ProductMetaData
      }
    }
  }
`;

export default IndexPage;
