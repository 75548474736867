import React, {useState} from 'react'
import styled from "@emotion/styled";
import { css } from "theme-ui"
import ConvertTextToComponent from '../../../../site/content/components/ConvertTextToComponent';

const styles = {
  activeTab: {
    display: `block !important`,
    whiteSpace: `pre`,
    textAlign: `center`
  },
  activeLink: {
    borderBottom: `3px solid #6910fb`,
    color: `black`
  },
  notActive: {
    borderBottom: `none`
  },
  tab: {
    display: `none`
  },
  highlight: {
    fontWeight: `700`
  },
  joinerRow: {
    maxWidth: `1080px`
  },
  button: {
    ':hover': {
      backgroundColor: `#c93d53`
    }
  }
}

const MobileAdminOrJoinerContainer = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  @media(min-width: 550px) {
    display: none
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 1em;
  margin-bottom: 7%
`

const Link = styled.a`
  color: #DBDBDB;
  font-weight: 700;
  margin: 0 7px;
  padding-bottom: 5px
`

const MobileAdminOrJoinerRow = styled.div`
  width: 100%;
`

const MobileAdminOrJoinerButton = styled.a`
  border-radius: 99px;
  width: 70%;
  padding: 0 10px !important;
  background: #fb4c68;
  border: 2px solid #fff !important;
  color: #fff;
  margin-bottom: 5em;
  margin-top: 1em;
  text-align: center;
  height: 41px;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`

const MobileAdminOrJoiner = ({content: {text_1, text_2, collection, buttons}, reference}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const titles = []
  titles.push(text_1.text, text_2.text)

  const renderedTabs = titles.map((title,index) => {
    const active = index === activeIndex ? styles.activeTab : ""
    return (
      <div key={index} css={css(active)} style={styles.tab} onClick={()=>onTitleClicked(index)} ref={reference}>
        { activeIndex === 0 ?
        <ConvertTextToComponent
          content={collection[0].description.detailedTexts}
          marginY='1.3em'
          /> :
        <ConvertTextToComponent
          content={collection[1].description.detailedTexts}
          marginY='1.3em'
          />
          }
      </div>
    )
  })

  const renderedLinks = titles.map((title,index) => {
    return (
      <Link key={index} style={index === activeIndex ? styles.activeLink : styles.notActive} onClick={()=>onTitleClicked(index)}>{title}</Link>
    )
  })

  const onTitleClicked = (index) => {
    setActiveIndex(index)
  }

  return (
    <MobileAdminOrJoinerContainer>
      <TitleContainer>
        {renderedLinks}
      </TitleContainer>
      <MobileAdminOrJoinerRow>
        {renderedTabs}
      </MobileAdminOrJoinerRow>
      <MobileAdminOrJoinerButton css={css(styles.button)} href={buttons?.[0].link}>
        {buttons?.[0].text}
      </MobileAdminOrJoinerButton>
    </MobileAdminOrJoinerContainer>
  )
}

export default MobileAdminOrJoiner
