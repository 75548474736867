import React from 'react'
import background from "../../../content/assets/landing/reasons/background.png"
import styled from "@emotion/styled";
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import getImage from '@solid-ui-components/utils/getImage'
import { css } from "theme-ui"
import Reveal from '@solid-ui-components/Reveal'

const styles = {
  container: {
    display: `flex`,
    flexFlow: `row wrap`,
    maxWidth: `720px`,
  },
  column: {
    width: `50%`,
    padding: `0 15px 30px`,
    textAlign: `left`,
    '@media(max-width: 480px)': {
      width: `100%`
    },
    '@media(max-width: 1024px)': {
      maxWidth: `323px`
    },
  },
  image: {
    width: `98px`,
    marginBottom: `1em`
  },
  title: {
    fontSize: `1rem`,
    color: `themeColor`,
    fontWeight: 700,
    marginTop: 0,
    marginBottom: `6px`
  },
  subtitle: {
    fontSize: `1rem`,
    color: `#000000`,
    margin: 0
  },
  button: {
    ':hover': {
      backgroundColor: `#c93d53`
    }
  },
  buttonName: {
   margin: 0
  }
}

const ReasonsContainer = styled.div`
  background: url(${background})};
  @media(max-width: 1024px) {
    background: none
  }
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: 100%;
  display: grid;
  justify-content: center;
  height: auto;
  padding: 10% 5% 0% 5%
`

const ReasonsRow = styled.div`
  max-width: 1080px;
  display: flex;
  justify-content: center;
  @media(max-width: 1024px) {
    display: grid
  }
`
const ReasonsColumn = styled.div`
  width: 30%;
  &:last-child {
    width: 70%;
    @media(max-width: 1024px) {
      width: 100%
    }
  };
  text-align: center;
  display: flex;
  &:first-of-type {
    display: block;
    @media(max-width: 1024px) {
      display: contents;
    }
  };
  flex-flow: column;
  justify-content: flex-start;

`

const Kicker = styled.h2`
  color: #8664db;
  font-weight: 700;
  line-height: 1.3;
  text-align: left;
  margin-top: 0
`

const Title = styled.h5`
  text-align: left;
  color: #000;
  font-weight: 600;
  line-height: 1.3;
  margin-top: 0
`

const Devices = styled(Reveal)`
  width: 439px;
  position: relative;
  right: 174px;
  top: 84px;
  @media(max-width: 1024px) {
    display: none
  }
`

const ReasonsButton = styled.a`
  border-radius: 99px;
  background-color: #fb4c68;
  color: #fff;
  border-color: rgba(0,0,0,0.05);
  font-weight: 800;
  text-transform: uppercase;
  padding: 0 1.2em;
  line-height: 2.4em;
  min-height: 2.5em;
  width: 249px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  height: fit-content;
  align-items: center;
`

const ReasonsButtonRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 60px 0 80px
`

const ReasonsCards = ({images, collection}) => {
  return (
    <div style={styles.container}>
      {
        collection.map((card, index) => {
          return (
            <Reveal css={css(styles.column)} key={index} effect="fadeInDown" delay={index * .25}>
              <Img style={styles.image} image={getImage({...images[index+1].src, __typename: "ImageSharp_small_square"})} alt={images[index+1].alt}/>
              <h3 css={css(styles.title)}>{card.title.text}</h3>
              <p css={css(styles.subtitle)}>{card.description.text}</p>
            </Reveal>
          )
        })
      }
    </div>
  )
}

const Reasons = ({content: {kicker, description, collection, buttons, images}}) => {
  const [button] = buttons

  return (
    <ReasonsContainer>
      <ReasonsRow>
        <ReasonsColumn>
          <Kicker>{kicker.text}</Kicker>
          <Title>{description.text}</Title>
          <Devices effect="fadeInLeft">
            <Img image={getImage({...images[0].src, __typename: "ImageSharp_large_square"})} alt={images[0].alt}/>
          </Devices>
        </ReasonsColumn>
        <ReasonsColumn>
          <ReasonsCards images={images} collection={collection}/>
        </ReasonsColumn>
      </ReasonsRow>
      <ReasonsButtonRow>
        <ReasonsButton css={css(styles.button)} href={button.link}><h6 style={styles.buttonName}>{button.text}</h6></ReasonsButton>
      </ReasonsButtonRow>
    </ReasonsContainer>
  )
}

export default Reasons
