import styled from '@emotion/styled';
import { Container, Box } from 'theme-ui';
import React from 'react';
import { Text } from 'theme-ui';
import { StaticImage } from 'gatsby-plugin-image';
import { css } from '@emotion/react';
import { BrowserView, MobileView } from 'react-device-detect';
import Reveal from '@solid-ui-components/Reveal';

const HeroContainer = styled.div`
  padding-right: 300px;
  max-width: 1110px;
  white-space: pre-wrap;
  color: white;
  @media (max-width: 1110px) {
    padding-right: 0px;
  }
  @media (max-width: 480px) {
    margin-top: 15%;
  }
`;

const HeroBlockTitle = styled(Text)`
  font-weight: 600;
  line-height: 1.3;
  text-align: left;
  margin-bottom: 30px;
  font-size: 45px !important;
  @media (max-width: 480px) {
    text-align: center;
    padding-bottom: 2%;
    margin-bottom: 30px;
    font-size: 35px !important;
  }
  @media (min-width: 704px) {
    padding-top: 0;
  }
`;

const HeroBlockSubtitle = styled(Text)`
  font-weight: normal;
  line-height: 1.3;
  text-align: left;
  margin-bottom: 40px;
  font-size: 25px;
  @media (max-width: 480px) {
    text-align: center;
  }
  @media (max-width: 480px) {
    margin-bottom: 20px;
    font-size: 20px
  }
`;

const HeroBlockButton = styled.a`
  border-radius: 99px;
  width: 40%;
  padding: 0 10px !important;
  background: #8664db;
  border: 2px solid #fff !important;
  color: white;
  margin-bottom: 1em;
  text-align: center;
  height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  @media (max-width: 480px) {
    width: 80%;
    margin-left: 10%;
  }
`;

const styles = {
  button: {
    height: `56px`,
    ':hover': {
      background: `#6b50af`,
    },
  },
  wrapper: {
    paddingBottom: '180px',
    paddingTop: '180px',
    position: 'relative',
    '@media (max-width: 480px)': {
      paddingBottom: '80px',
      paddingTop: '30%',
    },
    '@media (min-width: 1670px)': {
      paddingBottom: '350px',
    },
  },
  bgImage: {
    position: 'absolute',
    width: '100%',
    height: '110%',
    top: '0',
    left: '0',
    zIndex: -2,
  },
};

const HeroBlock = ({ content }) => {
  const [button] = content.buttons;
  return (
    <Box sx={styles.wrapper}>
      <MobileView>
        <StaticImage
          src='../../../content/assets/landing/hero-background.png'
          alt='backgroundImage'
          loading='lazy'
          objectFit='cover'
          layout='constrained'
          quality='100'
          formats={['webp', 'png']}
          css={styles.bgImage}
          width={500}
          objectPosition='15% 0'
        />
      </MobileView>
      <BrowserView>
        <StaticImage
          src='../../../content/assets/landing/hero-background.png'
          alt='backgroundImage'
          loading='lazy'
          objectFit='cover'
          layout='fullWidth'
          quality='100'
          formats={['webp', 'png']}
          css={styles.bgImage}
        />
      </BrowserView>
      <Container variant='container'>
        <Reveal effect='fadeInGrow' delay={0}>
          <HeroContainer>
            <HeroBlockTitle as='h1'>{content.text_1.text}</HeroBlockTitle>
            <HeroBlockSubtitle>{content.text_2.text}</HeroBlockSubtitle>
            <HeroBlockButton css={css(styles.button)} href={button.link}>
              <h5>{button.text}</h5>
            </HeroBlockButton>
          </HeroContainer>
        </Reveal>
      </Container>
      <Reveal effect='fadeInGrow' delay={.5} css={css`
            position: absolute;
            right: -275px;
            width: 1450px;
            top: 400px;
            @media (max-width: 1668px) {
              width: 1140px;
              top: 395px
            }
            @media (max-width: 1448px) {
              width: 1117px;
            }
            @media (max-width: 1024px){
              right: -35%;
              width: 100%;
              top: 500px;
            }
            @media(max-width: 820px) {
              top: 550px

            }
            @media(max-width: 700px) {
              top: 660px;
              width: 125%;
              right: -21%
            }
            @media(max-width: 480px) {
              display: none;
            }
          `}
      >
        <StaticImage
          src='../../../content/assets/landing/hero-devices.png'
          alt='backgroundImage'
          loading='lazy'
          objectFit='cover'
          layout='constrained'
          placeholder='tracedSVG'
          formats={['webp', 'png']}
          quality='100'
        />
      </Reveal>
    </Box>
  );
};

export default HeroBlock;
