import React from 'react';
import styled from '@emotion/styled';
import { css } from 'theme-ui';
import { StaticImage } from 'gatsby-plugin-image';
import Reveal from '@solid-ui-components/Reveal'

const styles = {
  icon: {
    width: `150px`,
    paddingTop: `.2em`,
  },
  netflix: {
    color: `#e70324`,
    fontSize: `1.2rem`,
    margin: 0,
    fontWeight: 700,
  },
  spotify: {
    color: `#00d95e`,
    fontSize: `1.2rem`,
    margin: 0,
    fontWeight: 700,
  },
  apple: {
    color: `#ee546a`,
    fontSize: `1.2rem`,
    margin: 0,
    fontWeight: 700,
  },
  office: {
    color: `#28a9ec`,
    fontSize: `1.2rem`,
    margin: 0,
    fontWeight: 700,
  },
  description: {
    fontSize: `1rem`,
    marginBottom: `1.3em`,
    fontWeight: 100,
    color: `#000000`,
    marginTop: `auto`,
  },
  price: {
    fontWeight: `bold`,
    fontSize: `1rem`,
    color: `#000000`,
  },
  column: {
    width: `170px`,
    justifyContent: `center`,
    '@media(min-width: 1158px)': {
      width: `72%`,
    },
    '@media(max-width: 548px)': {
      width: `177px`
    }
  },
  button: {
    ':hover': {
      backgroundColor: `#c93d53`
    }
  }
}

const ServicesBlockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  margin-top: 127px;
  @media (min-width: 480px) {
    margin-top: 280px;
  }
  @media (min-width: 530px) {
    margin-top: 160px;
  }
  @media (min-width: 767px) {
    margin-top: 20%;
  }
  @media (min-width: 1025px) {
    margin-top: 5%;
  }
`;

const ServicesBlockTitle = styled.h3`
  color: #000;
  line-height: 1.2;
  letter-spacing: 0.05em;
  font-weight: 700;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 80px;
  @media (min-width: 530px) {
    margin-top: 130px;
  }
  @media (min-width: 1024px) {
    margin-top: 200px;
  }
  @media (min-width: 1174px) {
    margin-top: 120px;
  }
`;

const ServicesContainer = styled.div`
  max-width: 889px;
  @media (max-width: 849px) {
    width: 75%;
  }
  @media (min-width: 850px) {
    width: 70%
  }
`;
const ServicesRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 549px) {
    display: grid;
  }
`;

const ServicesColumn = styled(Reveal)`
  width: 33.3%;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  @media (max-width: 549px) {
    width: auto;
  }
`;

const ServicesInfo = (props) => {
  return (
    <div css={css(styles.column)}>
      {props.image}
      {/* <img  /> */}
      <h4 style={props.class}>{props.title}</h4>
      <p style={styles.description}>{props.subtitle}</p>
      <p style={styles?.price}>{props?.price}</p>
    </div>
  );
};

const ServicesDescriptionRow = styled.div`
  display: grid;
  justify-items: center;
  width: 100%;
`;

const ServicesDescription = styled.p`
  color: #000000;
  font-weight: 600;
  margin-bottom: 1.3em;
  line-height: 1.6;
  text-align: center;
  margin-top: 30px;
  width: 83%;
  @media (max-width: 603px) {
    width: auto;
  }
`;

const ServicesButton = styled.a`
  border-radius: 99px;
  background-color: #fb4c68;
  color: #fff;
  border-color: rgba(0, 0, 0, 0.05);
  font-weight: 800;
  text-transform: uppercase;
  padding: 0 1.2em;
  line-height: 2.4em;
  min-height: 2.5em;
  width: 83%;
  text-decoration: none;
  display: flex;
  justify-content: center;
  @media (max-width: 603px) {
    width: auto;
  }
`;

const Services = ({ content }) => {
  const [button] = content.buttons;
  return (
    <ServicesBlockContainer>
      <ServicesContainer>
        <ServicesBlockTitle>{content?.kicker?.text}</ServicesBlockTitle>
        <ServicesRow>
          <ServicesColumn effect="fadeInLeft">
            <ServicesInfo
              class={styles.netflix}
              image={
                <StaticImage
                  src='../../../content/assets/landing/netflix-premium.png'
                  loading='lazy'
                  quality={70}
                  placeholder="tracedSVG"
                  alt='Netflix'
                  style={styles.icon}
                />
              }
              id='1'
              title={content?.collection[0].title.text}
              subtitle={content?.collection[0].description.text}
              price={content?.collection[0].text_1?.text}
            />
          </ServicesColumn>
          <ServicesColumn effect="fadeInLeft" delay={.4}>
            <ServicesInfo
              class={String(content?.collection[1].title.text).toLowerCase().includes('apple') ? styles.apple : styles.spotify}
              image={
                <StaticImage
                  src='../../../content/assets/landing/spotify.png'
                  placeholder="tracedSVG"
                  quality={70}
                  alt='Spotify'
                  loading='lazy'
                  style={styles.icon}
                />
              }
              id='1'
              title={content?.collection[1].title.text}
              subtitle={content?.collection[1].description.text}
              price={content?.collection[1].text_1?.text}
            />
          </ServicesColumn>
          <ServicesColumn effect="fadeInLeft" delay={.8}>
            <ServicesInfo
              class={styles.office}
              image={
                <StaticImage
                  src='../../../content/assets/landing/office.png'
                  placeholder="tracedSVG"
                  quality={70}
                  alt='Office'
                  loading='lazy'
                  style={styles.icon}
                />
              }
              id='1'
              title={content?.collection[2].title.text}
              subtitle={content?.collection[2].description.text}
              price={content?.collection[2].text_1?.text}
            />
          </ServicesColumn>
        </ServicesRow>
        <ServicesDescriptionRow>
          <ServicesDescription>
            {content?.description?.text}
          </ServicesDescription>
          <ServicesButton href={button.link}>{button.text}</ServicesButton>
        </ServicesDescriptionRow>
      </ServicesContainer>
    </ServicesBlockContainer>
  );
};

export default Services;
