import React, { useRef } from 'react'
import styled from "@emotion/styled"
import { GatsbyImage as Img, StaticImage } from 'gatsby-plugin-image'
import getImage from '@solid-ui-components/utils/getImage'
import { css } from "theme-ui"
import Reveal from '@solid-ui-components/Reveal'
import { useHasBeenVisible } from '../../../hooks/useVisibility'
import FullWidthPlaceHolder from '../../../content/components/shared/FullWidthPlaceHolder'

const styles = {
  image: {
    objectFit: `contain`,
  }
}

const GroupMembersSection = styled.div`
  height: 100%;
  position: relative;
  height: 800px;
  @media(min-width: 1024px) {
    height: 501px
  }
`
const GroupMembersContainer = styled.div`
  position: absolute;
  display: grid;
  justify-content: center;
  height: 100%;
  max-width: 1080px;
  @media(min-width: 1024px) {
    display: flex
  }
  @media(max-width: 1023px) {
    align-content: center;
  }
`

const GroupMembersTitle = styled.h1`
  font-weight: bold;
  text-align: center;
  color: #000;
  color: white;
  margin: 0px 0px 0.4em 0px;
  width: 100%;
  @media(min-width: 1024px) {
    text-align: left
  }
`

const GroupMembersSubtitle = styled.p`
  line-height: 1.6;
  text-align: center;
  color: white;
  font-weight: 600;
  margin: 0px;
  width: 100%;
  @media(min-width: 704px) and (max-width: 1023px) {
    white-space: pre;
  }
  @media(min-width: 1024px) {
    text-align: left
  }
`

const GroupMembersRow = styled(Reveal)`
  max-width: 1080px;
  margin: 90px 0px 30px 0px;
  padding: 4% 2%;
  text-align: center;
  &:last-child {
    margin-top: 8%
  }
  @media(min-width: 1024px) {
    width: 50%;
    display: grid;
    align-content: center;
    justify-content: space-around;
  }
  @media(max-width: 485px) {
    &:last-child {
      overflow: scroll;
    }
  }
`

const AdditionalContainer = styled.div`
  display: flex;
  justify-content: center;
  @media(min-width: 1024px) {
    display: flex;
    justify-content: center
  }
`

const ImageContainer = styled.div`
  @media(max-width: 485px) {
    overflow: scroll;
    width: 500px
  }
`

const GroupMembers = ({content: {collection, images}}) => {
  const groupRef = useRef();
  const hasScrolled = useHasBeenVisible(groupRef);

  return (
    hasScrolled ? 
    <GroupMembersSection>
      <StaticImage
      src="../../../content/assets/landing/group-members/background.png"
      alt="landingBg"
      quality="85"
      loading="lazy"
      layout="fullWidth"
      objectPosition="57% 0%"
      objectFit="cover"
      css={{
          position: "absolute",
          width: "100%",
          top: 0,
          left: 0,
          height: "100%",
          zIndex: -2,
        }}
      />
      <AdditionalContainer>
        <GroupMembersContainer>
          <GroupMembersRow effect="fadeInLeft">
            <GroupMembersTitle>{collection[0].title.text}</GroupMembersTitle>
            <GroupMembersSubtitle>{collection[0].description.text}</GroupMembersSubtitle>
          </GroupMembersRow>
          <GroupMembersRow effect="fadeInRight">
            <ImageContainer>
              <Img image={getImage({...images[0].src, __typename: "ImageSharp_medium_rectangular"})} alt={images[0].alt} style={css(styles.image)}/>
            </ImageContainer>
          </GroupMembersRow>
        </GroupMembersContainer>
      </AdditionalContainer>
    </GroupMembersSection> : <FullWidthPlaceHolder reference={groupRef} customStyle={{ height: 600, background: "linear-gradient(to right bottom,#B475C1, #DB72A2)" }} />
  )
}

export default GroupMembers
