import React from 'react'
import styled from "@emotion/styled";
import Reveal from '@solid-ui-components/Reveal'

const HowItWorksContainer = styled.div`
  height: auto;
  width: 100%;
  @media(max-width: 480px) {
    display: none
  }
  `

const HowItWorksTitle = styled.h1`
  font-weight: bold;
  text-align: center;
  color: #000;
`

const HowItWorksSubtitle = styled.p`
  line-height: 1.6;
`

const HowItWorksRow = styled(Reveal)`
  max-width: 1080px;
  margin: auto;
  padding: 15px 30px;
`

const HowItWorks = ({content}) => {
  return (
    <HowItWorksContainer>
      <HowItWorksRow>
        <HowItWorksTitle>{content?.title?.text}</HowItWorksTitle>
        <HowItWorksSubtitle>{content?.description?.text}</HowItWorksSubtitle>
      </HowItWorksRow>
    </HowItWorksContainer>
  )
}

export default HowItWorks
