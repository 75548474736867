import React from 'react'
import styled from "@emotion/styled"
import { css } from "theme-ui"
import { StaticImage } from "gatsby-plugin-image"
import Reveal from '@solid-ui-components/Reveal'

const styles = {
  title: {
    color: `themeColor`,
  },
  answer: {
    color: `omegaDarker`
  }
}

const QuestionsSection = styled.div`
  height: 100%;
  height: 548px;
  position: relative;
  @media(max-width: 549px) {
    height: auto
  }
`
const QuestionsContainer = styled(Reveal)`
  display: grid;
  justify-content: center;
  padding: 80px 30px;
  height: 100%;
  max-width: 1080px;
  @media(max-width: 480px) {
    padding: 10px 30px;
  }
`

const QuestionsTitle = styled.h1`
  text-align: center;
  margin: 0;
  font-weight: 100;
  @media(max-width: 480px) {
    line-height: 1.3;
  }
`

const QuestionsSubtitle = styled.h2`
  text-align: center;
  margin: 0px;
  font-weight: bold;
  @media(max-width: 480px) {
    line-height: 1.3;
  }
`

const Answer = styled.p`
  text-align: left;
  margin: 16px 0px 50px 0px;
  &:last-child {
    white-space: pre-line
  }
`

const WrapperContainer = styled.div`
  display: flex;
  justify-content: center
`

const Questions = ({content: {kicker, collection}}) => {
  return (
    <QuestionsSection>
      <StaticImage
        src="../../../content/assets/landing/graphic-elements.png"
        alt="landingBg"
        quality="85"
        loading="lazy"
        layout="fullWidth"
        placeholder="none"
        objectPosition="48% 79%"
        objectFit="cover"
        css={{
          position: 'absolute',
          width: '100%',
          top: 0,
          left: 0,
          height: '100%',
          zIndex: -2,
      }}/>
      <WrapperContainer>
        <QuestionsContainer>
          <QuestionsTitle css={css(styles.title)}>{kicker.text}</QuestionsTitle>
          <QuestionsSubtitle css={css(styles.title)}>{collection[0].title.text}</QuestionsSubtitle>
          <Answer css={css(styles.answer)}>{collection[0].text_1.text}</Answer>
          <QuestionsSubtitle css={css(styles.title)}>{collection[1].title.text}</QuestionsSubtitle>
          <Answer css={css(styles.answer)}>{collection[1].text_1.text}</Answer>
        </QuestionsContainer>
      </WrapperContainer>
    </QuestionsSection>
  )
}

export default Questions
