import React, {useState} from 'react'
import styled from "@emotion/styled";
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { css } from "theme-ui"
import getImage from '@solid-ui-components/utils/getImage'
import Reveal from '@solid-ui-components/Reveal'

const styles = {
  activeTab: {
    display: `block !important`
  },
  activeLink: {
    borderBottom: `3px solid #6910fb`,
    color: `black`
  },
  notActive: {
    borderBottom: `none`
  },
  tab: {
    display: `none`
  },
  image: {
    // width: `67%`
  },
  highlight: {
    fontWeight: `700`
  },
  joinerRow: {
    maxWidth: `1080px`
  },
  button: {
    ':hover': {
      backgroundColor: `#c93d53`
    }
  }
}

const AdminOrJoinerContainer = styled.div`
  height: auto;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  @media(max-width: 549px) {
    display: none
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 1em;
  margin-bottom: 7%
`

const Link = styled.a`
  color: #DBDBDB;
  font-weight: 700;
  margin: 0 7px;
  padding-bottom: 5px
`

const AdminOrJoinerRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  @media(max-width: 549px) {
    display: grid;
  }
`

const AdminOrJoinerColumn = styled(Reveal)`
  width: auto;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0 15px 30px;
  @media(min-width: 550px) {
    width: 33.3%
  }
`

const AdminOrJoinerButton = styled.a`
  border-radius: 99px;
  width: 260px;
  padding: 0 10px !important;
  background: #fb4c68;
  border: 2px solid #fff !important;
  color: #fff;
  margin-bottom: 1em;
  text-align: center;
  height: 52px;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`

const Admin = ({images}) => {
  const adminImages = images.slice(0,3)

  return (
    <AdminOrJoinerRow>
      {
        adminImages.map((image, index)=> {
          return (
          <AdminOrJoinerColumn key={index}>
            <Img
              style={styles.image}
              image={getImage({...image.src, __typename: "ImageSharp_medium_square"})}
              alt={image.alt}
            />
            <p style={styles.highlight}>{image.alt}</p>
          </AdminOrJoinerColumn>
        )})
      }
    </AdminOrJoinerRow>
   )
}

const Joiner = ({images}) => {
  const joinerImages = images.slice(3)

  return (
    <AdminOrJoinerRow style={styles.joinerRow}>
      {
        joinerImages.map((image, index)=> {
          return (
          <AdminOrJoinerColumn key={index}>
            <Img style={styles.image} image={getImage({...image.src, __typename: "ImageSharp_medium_square"})} alt={image.alt}/>
            <p style={styles.highlight}>{image.alt}</p>
          </AdminOrJoinerColumn>
        )})
      }
    </AdminOrJoinerRow>
  )
}

const AdminOrJoiner = ({content: {collection, images, buttons}}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const titles = []
  titles.push(collection[0].text_1.text, collection[1].text_2.text)

  const renderedTabs = titles.map((title,index) => {
    const active = index === activeIndex ? styles.activeTab : ""
    return (
      <div key={index} css={css(active)} style={styles.tab} onClick={()=>onTitleClicked(index)}>
        { activeIndex === 0 ? <Admin images={images}/> : <Joiner images={images}/>}
      </div>
    )
  })

  const renderedLinks = titles.map((title,index) => {
    return (
      <Link key={index} style={index === activeIndex ? styles.activeLink : styles.notActive} onClick={()=>onTitleClicked(index)}>{title}</Link>
    )
  })

  const onTitleClicked = (index) => {
    setActiveIndex(index)
  }

  return (
    <AdminOrJoinerContainer>
      <TitleContainer>
        {renderedLinks}
      </TitleContainer>
      <AdminOrJoinerRow>
        {renderedTabs}
      </AdminOrJoinerRow>
      <AdminOrJoinerButton css={css(styles.button)} href={buttons?.[0].link}>
        <h5>{buttons?.[0].text}</h5>
      </AdminOrJoinerButton>
    </AdminOrJoinerContainer>
  )
}

export default AdminOrJoiner
