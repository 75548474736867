import { useEffect } from 'react';

export default (pathname, allBlockContents) => {
  // useEffect(() => {
  //   (window as any).dataLayer.push({ event: 'Control-Redirect-Test' });
  // }, []);
  if (pathname.includes('/control')) {
    const newAllBlockContents = JSON.parse(
      JSON.stringify(allBlockContents)
        .replaceAll('?ab=old_home_page', '')
        .replaceAll(
          'dev-frontend-tp-react.togetherprice.com/signup',
          'dev-frontend-tp-react.togetherprice.com/signup/ab_test_public'
        )
        .replaceAll(
          'staging-frontend-tp-react.togetherprice.com/signup',
          'staging-frontend-tp-react.togetherprice.com/signup/ab_test_public'
        )
        .replaceAll(
          'app.togetherprice.com/signup',
          'app.togetherprice.com/signup/ab_test_public'
        )
        .replaceAll(
          'dev-frontend-tp-react.togetherprice.com/login',
          'dev-frontend-tp-react.togetherprice.com/login?experiment_name=PUBLIC_NETWORK&experiment_variant=A'
        )
        .replaceAll(
          'staging-frontend-tp-react.togetherprice.com/login',
          'staging-frontend-tp-react.togetherprice.com/login?experiment_name=PUBLIC_NETWORK&experiment_variant=A'
        )
        .replaceAll(
          'app.togetherprice.com/login',
          'app.togetherprice.com/login?experiment_name=PUBLIC_NETWORK&experiment_variant=A'
        )
    );
    return newAllBlockContents;
  } else return allBlockContents;
};
