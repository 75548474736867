import React from 'react'
import styled from "@emotion/styled";
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import getImage from '@solid-ui-components/utils/getImage'
import { StaticImage } from 'gatsby-plugin-image';
import Reveal from '@solid-ui-components/Reveal'

const PartnersSection = styled.div`
  position: relative;
  height: 193px;
  @media(max-width: 549px) {
    height: auto;
    padding-bottom: 30px
  }
`

const PartnersContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  line-height: normal;
  padding-top: 30px
`

const PartnersTitle = styled.h1`
  color: #000;
  line-height: 1.3;
  margin-bottom: 20px;
  font-weight: 700;
  text-align: center;
  margin-top: 0
`

const PartnersRow = styled.div`
  display: flex;
  width: 420px;
  justify-content: space-around;
  align-items: center;
  @media(max-width: 549px) {
    display: flex;
    flex-wrap: wrap;
    width: auto;
  }
`

const Image = styled.a`
  width: 55%;
  @media(max-width: 549px) {
    width: ${({ index }) => {
      if (index === 2) return '30%';
      return '44%';
    }};
  }
`

const PartnerCard = styled(Reveal)`
  width: 33.3%;
  @media(max-width: 549px) {
    width: ${({ index }) => {
      if (index === 2) return '100%';
      return '50%';
    }};
  }
  display: flex;
  justify-content: center;
  align-items: center;
`

const Partners = ({content: {kicker, images}}) => {
  return (
    <PartnersSection>
      <StaticImage
        src="../../../content/assets/landing/partners/background.png"
        alt="partnersBG"
        quality="85"
        loading="lazy"
        layout="fullWidth"
        objectFit="cover"
        objectPosition="50% 100%"
        css={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: -2,
          }}
      />
      <PartnersContainer>
        <PartnersTitle>
          {kicker.text}
        </PartnersTitle>
        <PartnersRow>
          {
            images.map((image, index) => (
                <PartnerCard key={ image.link } index={ index } effect="fadeInGrow" delay={ index * .1 }>
                  <Image href={ image.link } index={ index }>
                    <Img
                      image={ getImage({ ...image.src, __typename: "ImageSharp_medium" }) }
                      alt={ image.alt }
                      objectFit="fill"
                    />
                  </Image>
                </PartnerCard>
              ))
          }
        </PartnersRow>
      </PartnersContainer>
    </PartnersSection>
  )
}

export default Partners
